import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'redeem-post-test',
  props: {
    customerName: {
      type: String,
      require: true
    },
    activityId: {
      type: Number,
      require: true
    },
    activityItemId: {
      type: Number,
      require: true
    },
    targetUrl: {
      type: String,
      require: true
    },
    scope: {
      type: String,
      require: true
    },
    pmoRedeemCode: {
      type: String
    }
  },
  data() {
    return ({
      payloads: {
        redeemCode: this.pmoRedeemCode || ''
      },
      redeemError: '',
      isRedeeming: false
    })
  },
  components: {
    TextField: () => import(/* webpackChunkName: "textfield" */ '@/components/forms/TextField'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button')
  },
  methods: {
    ...mapActions('common', ['setThankYouPage', 'showLoading', 'hideLoading']),
    ...mapActions('prakerja', ['redeemCode']),
    async doRedeemCode() {
      this.showLoading()
      this.isRedeeming = true
      const payloads = {
        nama: '',
        email: '',
        hp: '',
        provinsi: '',
        kode: '',
        referral_code: '',
        ecommerce: '',
        customer_id: '',
        redemptionCode: this.payloads.redeemCode,
        scope: this.scope,
        activityId: this.activityId,
        activityItemId: this.activityItemId
      }
      await this.redeemCode({
        payloads
      }).then(
        (response) => {
          const redirectUrl = response.data.redirect_url
          this.hideLoading()
          window.location.href = redirectUrl
        },
        () => {
          this.redeemError = 'Kode Redeem tidak ditemukan'
          this.isRedeeming = false
          this.hideLoading()
        }
      )
    }
  }
}
